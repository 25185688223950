<template>
	<div>
		<b-card class="mr-1 p-1" no-body>
			<b-row>
				<b-col class="d-flex align-items-center" cols="10">
					<b-nav pills class="m-0" style="gap: 1rem">
						<b-nav-item
							:to="{ name: 'preparacion-settings-suplements' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Suplementos
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'preparacion-settings-qualities' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Cualidades
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'preparacion-settings-seasons' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Temporadas
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'preparacion-settings-defects' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Defectos
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'preparacion-settings-exercices' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Ejercicios
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'preparacion-settings-reasons' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Motivos
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'preparacion-settings-blocks' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Bloques
						</b-nav-item>
					</b-nav>
				</b-col>
				<b-col class="d-flex justify-content-end" style="gap: 1rem">
					<b-button
						v-if="$route.name == 'preparacion-settings-vitamins'"
						@click="childAction = 'create-vitamin'"
						variant="success"
					>
						Crear suplemento
					</b-button>
					<b-button
						v-if="$route.name == 'preparacion-settings-qualities'"
						@click="childAction = 'create-quality'"
						variant="success"
					>
						Crear cualidad
					</b-button>
					<b-button
						v-if="$route.name == 'preparacion-settings-seasons'"
						@click="childAction = 'create-season'"
						variant="success"
						:disabled="actionBlocked"
					>
						Crear temporada
					</b-button>
					<b-button
						v-if="$route.name == 'preparacion-settings-defects'"
						@click="childAction = 'create-defect'"
						variant="success"
					>
						Crear defecto
					</b-button>
					<b-button
						v-if="$route.name == 'preparacion-settings-exercices'"
						@click="childAction = 'create-exercise'"
						variant="success"
					>
						Crear ejercicio
					</b-button>
					<b-button
						v-if="$route.name == 'preparacion-settings-reasons'"
						@click="childAction = 'create-reason'"
						variant="success"
					>
						Crear Motivo
					</b-button>
					<b-button
						v-if="$route.name == 'preparacion-settings-blocks'"
						@click="childAction = 'create-block'"
						variant="success"
					>
						Crear Bloque
					</b-button>
				</b-col>
			</b-row>
		</b-card>
		<b-card class="p-1" no-body>
			<router-view :action="childAction" @actionDone="childAction = ''" @blocked="actionBlocked = true" />
		</b-card>
	</div>
</template>

<script>
export default {
	data: () => ({
		childAction: "",
		actionBlocked: false,
	}),
	watch: {
		"$route.name"() {
			this.actionBlocked = false;
		},
	},
};
</script>
